import React, { Component } from 'react'
import Sidebar from './common/sidebar_components/sidebar';
import Footer from './common/footer';
import Header from './common/header_components/header';
import { Route, Switch,Redirect } from 'react-router-dom';
import Dashboard from './dashboard';
import Categoria from './maestros/categoria/Categoria';
import AuthRoute from '../AuthGuard/AuthRoute';
import Producto from './maestros/producto/Producto';
import Pasillo from './maestros/pasillos/Pasillo';
import Especiales from './maestros/especiales/Especiales';
import OrdenesActivas from './maestros/ordenes/OrdenesActivas';
import OrdenesCompletadas from './maestros/ordenes/OrdenesCompletadas';
import OrdenesCanceladas from './maestros/ordenes/OrdenesCanceladas';
import Users from './users';
import Adicional from './maestros/adicionales/Adicional';
import Aliado from './maestros/aliados/Aliado';
import Usuario from './maestros/Usuarios/Usuario';
import Sugerencias from './Sugerencias';
import ProductoSugerido from './maestros/ProductoSugerido/ProductoSugerido';
import Finanzas from './Finanzas';
import ProductoPendiente from './maestros/producto-pendiente/ProductoPendiente';
import { getUser } from '../servicios';
import { orderAlert } from '../funciones/alert';
import ProductoPocoStock from './maestros/ProductoPocoStock/ProductoPocoStock';
import audio from '../assets/sounds/alarm.mp3'

export class App extends Component {
    constructor(props){
        super(props);
        this.state ={ }
    }

    componentDidMount(){
        orderAlert()
    }
    render() {
        return (
            <div>
                <div className="page-wrapper" >
                    <Header />
                    <div className="page-body-wrapper">
                        <Sidebar />
                        <div className="page-body">
                            <Switch>
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/home`} component={Dashboard} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/sugerencias`} component={Sugerencias} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/users`} component={Users} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/categoria`} component={Categoria} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/especiales`} component={Especiales} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/producto`} component={Producto} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/producto-pendiente`} component={ProductoPendiente} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/producto-sugerido`} component={ProductoSugerido} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/producto-poco`} component={ProductoPocoStock} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/pasillo`} component={Pasillo} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/adicional`} component={Adicional} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/aliado`} component={Aliado} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/usuario`} component={Usuario} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/finanzas`} component={Finanzas} />

                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/ordenesactivas`} component={OrdenesActivas} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/ordenescompletadas`} component={OrdenesCompletadas} />
                                <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard/maestros/ordenescanceladas`} component={OrdenesCanceladas} />
                                <Route path="*">
                                    <Redirect to="/404" />
                                </Route>
                            </Switch>
                        </div>
                        <Footer />
                        <audio id="alarm" src={audio} muted></audio>
                    </div>
                </div>
            </div>
        )
    }
}

export default App
