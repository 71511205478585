import React, { Component,Fragment } from 'react'
import {Link} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import ModalLogo from '../ModalLogo';
//images import
import man from '../../../assets/images/dashboard/man.png'
export class User_menu extends Component {

    constructor(props){
        super(props)
        this.logout=this.logout.bind(this)
        this.state={
            openLogo:false
        }
    }

    logout(){
        localStorage.clear()
        this.props.history.push('/')
    }

    openLogo = (e) => {

        e.preventDefault()
        this.setState({
            openLogo:true
        })
        
    }

    closeLogo = () => {

        this.setState({
            openLogo:false
        })
        
    }
    
    


    render() {
        const {openLogo}=this.state
        return (
            <Fragment>
                    <li className="onhover-dropdown">
                        <div className="media align-items-center">
                            <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" />
                            <div className="dotted-animation"><span className="animate-circle"></span><span className="main-circle"></span></div>
                        </div>
                        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                            <li onClick={(e)=>{this.openLogo(e)}}><Link to="#"><i data-feather="log-out"></i>Actualizar logo</Link></li>
                            <li onClick={this.logout}><Link to="#"><i data-feather="log-out"></i>Cerrar Sesión</Link></li>
                        </ul>
                    </li>
                    <Modal open={openLogo} onClose={this.closeLogo} >
                        <ModalLogo close={this.closeLogo} />
                    </Modal>
            </Fragment>
        )
    }
}

export default withRouter(User_menu)
