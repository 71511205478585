import React, { Component,Fragment } from 'react'
import Modal from 'react-responsive-modal';
import Breadcrumb from './common/breadcrumb';
import { getData, getArrayByTipo, getUser } from '../servicios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { calcularPorcentaje, calcularDelivery, calcularGanaciaTotal, calcularFees , calcularTodas } from '../funciones/calculos';
import { excelSanitize, transformAllyOrders } from '../funciones/general';

export class Finanzas extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            searchInput: "",
            filteredData: [],
            openHora:false,
        };
        this.cargarTabla=this.cargarTabla.bind(this)
    }


    handleChange = (e) => {

        const { name,value}= e.target
        this.setState({
            [name]: value
        })
        
    }
    



    async cargarTabla(e){

        e.preventDefault()

        const response=await getData("ally-completed-orders?fi="+this.state.fi+"&ff="+this.state.ff+"&id="+getUser().ally_id)
        //const response=await getData("completed-orders")
        const json=transformAllyOrders(response['data'])

        this.setState({
            data:json,
        })

    }

    render() {

        const { data,open,param,openHora } = this.state;

        const list=data.map((order,indexOrder)=>{

            let total=0

            order.productos.map((p)=>{
                let por=parseFloat(p.a_fee)
                total+=calcularPorcentaje(p.total,100-por)
            })

            return(

                order.productos.map((value,i)=>{

                    let por_ganancia=parseFloat(value.a_fee)

                    return(
                        <>
                        <tr key={i}>
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {order.pedido}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {order.fecha}
                                </td>
                                :<></>
                            }
                            <td>
                                {value.sku?value.sku:'N/A'}
                            </td>
                            <td>
                                {value.producto}
                            </td>
                            <td>
                                {value.amount}
                            </td>
                            <td>
                                {excelSanitize(calcularPorcentaje(value.total,100-por_ganancia).toFixed(2))}
                            </td>
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {total.toFixed(2)}
                                </td>
                                :<></>
                            }        
                        </tr>
                        </>
                    )
                })
            )
            
        })

        return (
            <Fragment>

                <Breadcrumb title="Finanzas"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">

                                    <form onSubmit={this.cargarTabla}>

                                        <div className="form-row">

                                            <div className="form-group col-md-4">
                                                <label htmlFor="fi">Fecha de inicio</label>
                                                <input name="fi" type="date" className="form-control" onChange={this.handleChange} required />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label htmlFor="ff">Fecha de fin</label>
                                                <input name="ff" type="date" className="form-control" onChange={this.handleChange} required />
                                            </div>                                         

                                        </div>

                                        <button className="btn btn-secondary mr-5">
                                            Generar tabla
                                        </button>

                                        {
                                            data.length>0?
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn btn-secondary"
                                                table="table-to-xls"
                                                filename="finanzas"
                                                sheet="finanzas"
                                                buttonText="Exportar Excel"
                                            />
                                            :''
                                        }

                                        

                                    </form>
                                             
                                    <div className="user-status table-responsive latest-order-table mt-4" style={{height: "600px"}}>
                                        <table className="table table-bordernone " id="table-to-xls">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Pedido</th>
                                                    <th scope="col">fecha</th>
                                                    <th scope="col">Codigo</th>
                                                    <th scope="col">Descripción</th>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col">Ganancia</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default Finanzas