import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { postData, setHeaders, getHeaders } from '../../servicios';

export class LoginTabset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.login=this.login.bind(this)
    }

    handleChange(e) {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    async login(e){

        e.preventDefault()
        try{
            const response= await postData("manager_auth/sign_in",this.state)
            localStorage.setItem("user",JSON.stringify(response['data'].data))
            setHeaders(response.headers)
            console.log("headers login:",getHeaders())
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard/home`)
        }catch(err){
            console.log(err)
        }
    }
    render() {
        return (
            <div>
                <Fragment>
                    <Tabs>
                        <TabList className="nav nav-tabs tab-coupon" >
                            <Tab className="nav-link"><User />Login</Tab>
                        </TabList>

                        <TabPanel>
                            <form className="form-horizontal auth-form" onSubmit={this.login}>
                                <div className="form-group">
                                    <input required name="email" type="email" className="form-control" placeholder="Correo" id="exampleInputEmail1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <input required name="password" type="password" className="form-control" placeholder="Contraseña" onChange={this.handleChange} />
                                </div>
                                <div className="form-button">
                                    <button className="btn btn-primary" type="submit">Login</button>
                                </div>
                            </form>
                        </TabPanel>
                    </Tabs>
                </Fragment>
            </div>
        )
    }
}

export default withRouter(LoginTabset)

