import React, { Component,Fragment } from 'react'
import empty from '../../assets/images/empty.jpg'
import { getData, getUser, postData, putData } from '../../servicios'
import Loader from './Loader'

export class ModalAliado extends Component {

    constructor(props){
        super(props)

        this.state={
            loading:true,
            data:'',
            img:null
        }
        

        this.handleChange=this.handleChange.bind(this)


    }

    handleChange(e){
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                img: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        this.editar()
        this.refs.btn.setAttribute("disabled", "disabled");
    }

    async crear(){

        const json={
            ally:{
                name:this.state.name,
                desc:this.state.desc,
                pri:this.state.pri,
                tipo: this.state.tipo
            }
        }

        if(this.state.preview){
            json.ally.image=this.state.preview
        }else{
            json.ally.image=empty
        }

        try{
            await postData("allies",json)
            this.props.cargar()
            this.props.close()
        }catch(err){
            console.log(err)
        }

    }

    async editar(){
        const json={
            ally:{
            }
        }

        if(this.state.img){
            json.ally.image=this.state.img
        }

        try {
            await putData("allies",getUser().ally_id,json)
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }


    async componentDidMount(){

        try {

            const req = await getData('allies/'+getUser().ally_id)
            this.setState({
                data:req.data,
                loading:false
            })
            
        } catch (error) {
            
        }

    }



    render() {

        const {loading,data,img} = this.state

        let preview
        if(img){
            preview=<img src={img} alt="preview" className="center"/>
        }else{
            preview=<img src={data.image} alt="preview" className="center"/>
        }

        console.log(data)

        return (
            <Fragment>
                
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Logo</h5>
                </div>

                {
                    !loading?
                    <div className="modal-body">
                        <form onSubmit={this.submit}>

                            {preview}
                            <div className="form-group">
                                <label htmlFor="image" className="col-form-label">Escoge una imagen :</label>
                                <input className="form-control" name="image" type="file" onChange={this.handleFile}  accept="image/jpeg" required />
                            </div>
                            <div className="modal-footer">
                                <button ref='btn' type="submit" className="btn btn-secondary" >Guardar</button>
                            </div>
                        </form>
                    </div>
                    :
                    <div className="tac">
                        <Loader/>
                    </div>
                    
                }
                

            </Fragment>
        )
    }
}

export default ModalAliado
