import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';
import './index.scss';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';

// Components
import Login from './components/auth/login';
import NotFound from './components/common/NotFound';
import NotAuthRoute from './AuthGuard/NotAutRoute';
import AuthRoute from './AuthGuard/AuthRoute';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyDSkgPeyrBm0ze1r20Vdy2LxEb0Ivhvowk",
    authDomain: "fluio-aliados.firebaseapp.com",
    databaseURL: "https://fluio-aliados.firebaseio.com",
    projectId: "fluio-aliados",
    storageBucket: "fluio-aliados.appspot.com",
    messagingSenderId: "571934838207",
    appId: "1:571934838207:web:c2eb673f5de54a8203650a"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);




class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>
                        <NotAuthRoute exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard`} component={App} />
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={NotFound} />
                        <Route path="*" >
                            <Redirect to="/404" />
                        </Route>
                        

                    {/*    
                        <App>
                            <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
                            <Route exact path={`${process.env.PUBLIC_URL}/maestros/categoria`} component={Categoria} />                 
                        </App>
                        */}     
                        
                        
                        
                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


