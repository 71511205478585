import {
    Home,
    List,
    Archive,
    Clipboard
} from 'react-feather';

export const MENUITEMS = [
    {
        path: '/dashboard/home', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    /**
    {
        path: '/dashboard/users', title: 'Usuarios', icon: User, type: 'link', badgeType: 'primary', active: false
    },
     */
    {
        title: 'Gestión', icon: List, type: 'sub', active: false, children: [

            
            { path: '/dashboard/maestros/producto-pendiente', title: 'Cargar productos', type: 'link' },
            { path: '/dashboard/maestros/producto', title: 'Productos publicados', type: 'link' },
            { path: '/dashboard/maestros/producto-poco', title: 'Productos por agotarse', type: 'link' },

            /**

            { path: '/dashboard/maestros/categoria', title: 'Categorias', type: 'link' },
            { path: '/dashboard/maestros/especiales', title: 'Categorias Especiales', type: 'link' },
            { path: '/dashboard/maestros/pasillo', title: 'Pasillo', type: 'link' },
            { path: '/dashboard/maestros/producto-sugerido', title: 'Producto Sugerido', type: 'link' },
            { path: '/dashboard/maestros/adicional', title: 'Adicional', type: 'link' },
            { path: '/dashboard/maestros/aliado', title: 'Aliados', type: 'link' },
            { path: '/dashboard/maestros/usuario', title: 'Usuarios', type: 'link' }

            */

        ]

    },
    {
        title: 'Ordenes', icon: List, type: 'sub', active: false, children: [
            { path: '/dashboard/maestros/ordenescompletadas', title: 'Completadas', type: 'link' },

            /**

            { path: '/dashboard/maestros/ordenesactivas', title: 'Proceso', type: 'link' },
            { path: '/dashboard/maestros/ordenescanceladas', title: 'Canceladas', type: 'link' }

            */

        ]

    },
    {
        title: 'Reportes', icon: Clipboard, type: 'sub', active: false, children: [
            { path: '/dashboard/finanzas', title: 'Finanzas', type: 'link' },
        ]

    },
    /** 
    {
        path: '/dashboard/sugerencias', title: 'Sugerencias', icon: Archive, type: 'link', badgeType: 'primary', active: false
    },
    */
]
