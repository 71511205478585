import Swal from "sweetalert2"
import { getData, getUser } from "../servicios"
let id=0
let start=true
const timeout=5000

export const orderAlert = async () => {

    if(id==0 && start){

        try {

            const req = await getData("ally-current-orders?id="+getUser().ally_id)

            start=false

            let comId=findMaxID(req.data,id)
            if(comId>id){
                id=comId
            }
    
            if(!isHome(window.location.pathname) && req.data.length>0){
    
                const question=await infoOrderQuestion(req.data.length)
                if(question.isConfirmed){
                    window.location.href="/dashboard/home"
                }else{
                    setTimeout(() => {

                        orderAlert()
                        
                    }, timeout);
                }
    
            }else{

                setTimeout(() => {

                    orderAlert()
                    
                }, timeout);

            }
            
            
        } catch (error) {

            setTimeout(() => {

                orderAlert()
                
            }, timeout);
            
        }

    }else{

        try {

            const req = await getData("ally-current-orders?id="+getUser().ally_id)

            let comId=findMaxID(req.data,id)

            if(comId>id){

                document.getElementById('alarm').play();
                document.getElementById('alarm').muted = false;

                id=comId

                const question=await infoNewOrderQuestion(req.data.length)
                if(question.isConfirmed){
                    window.location.href="/dashboard/home"
                }else{
                    setTimeout(() => {

                        orderAlert()
                        
                    }, timeout);
                }

            }else{

                setTimeout(() => {

                    orderAlert()
                    
                }, timeout);
                
            }
            
        } catch (error) {

            setTimeout(() => {

                orderAlert()
                
            }, timeout);
            
        }

    }

}

const infoOrderQuestion = (number) => {

    return Swal.fire({
        title: 'Tienes ('+number+') pedido(s) en tu dashboard',
        text: "Quieres verlos?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
    })
    
}

const infoNewOrderQuestion = (number) => {

    return Swal.fire({
        title: 'Han ingresado nuevos pedidos',
        text: "Quieres verlos?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
    })
    
}

const isHome = (val) => {

    return val=="/dashboard/home"
    
}

const findMaxID = (array,max) => {

    let newMax

    array.forEach(val => {
        if (val.id > max) {
          newMax = val.id;
        }
    });

    return newMax?newMax:max
    
}



