import { getArrayByTipo, getUser } from "../servicios";
import { getDiaMesEspanol, getDiaMesEspanolHuertaCongelado } from "./momentEspanol";
import { HuertaCoreMatchOrden } from "./Ordenes";
import * as moment from 'moment';

export const capitalizeWord = (value) => {

    return value.charAt(0).toUpperCase() + value.slice(1);
    
}

export const getStatusText = (status) => {

    switch (status) {
        case "OK":
            return 'Aprobada'
            break;
        case "PENDING":
            return 'Pendiente'
            break;
        case "NOT_AUTHORIZED":
            return 'Rechazada'
            break;
        case "FAILED":
            return 'Fallida'
            break;
        default:
            return ''
            break;
    }
    
}

export const excelSanitize = (value) => {

    const san=value.toString().replace(/\./g, ',')
    return san
    
}

export const textoEntrega = (orden) => {

    let string=''
    const productos=orden.productos

    const snack=getArrayByTipo(productos,0)
    const core=getArrayByTipo(productos,1)
    const huerta=getArrayByTipo(productos,4)
    const cena=getArrayByTipo(productos,6)

    const match=HuertaCoreMatchOrden(huerta,core)

    if(snack.length>0){
        string+=textoTipoEntregaSnack(snack[0])
    }

    if(core.length>0){
        string+=textoTipoEntregaCore(core[0])
    }

    if(huerta.length>0){
        string+=textoTipoEntregaHuerta(huerta[0])
    }

    return string
    
}

export const textoTipoEntregaSnack = (value) => {

    if(value.hora=="1"){
        return "Snack: Menos de 90 minutos."
    }else{
        return "Snack: "+getDiaMesEspanol(value.fecha_i)+" de "+getTimeRange(value.hora)+"."
    }
    
}

export const textoTipoEntregaHuerta = (value) => {

    return "Huerta: "+getDiaMesEspanolHuertaCongelado(value.fecha_i)+" a las "+value.hora
}

export const textoTipoEntregaCore = (value) => {

    if(value.hora=="1"){
        return "Mercado: Menos de 90 minutos."
    }else{
        return "Mercado: "+getDiaMesEspanol(value.fecha_i)+" de "+getTimeRange(value.hora)+"."
    }
    
}

export const getTimeRange = (value) => {

    return value+" a "+moment(value,'HH:mm').add(30,'minutes').format('HH:mm')
    
}

export const transformAllyOrders = (data) => {

    let newOrders=[]

    data.map((order)=>{


        const filterProducts=order.productos.filter((p)=>p.ally_id==getUser().ally_id)
        order.productos=filterProducts
        newOrders.push(order)

    })

    return newOrders

}



