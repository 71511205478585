import React, { Component,Fragment } from 'react'
import { postData,putData,getData, allData } from '../../../servicios'
import empty from '../../../assets/images/empty.jpg'

export class ModalProductoPocoStock extends Component {

    constructor(props){
        super(props)
        this.state={
            price:props.param.price,
            name:props.param.name,
            desc:props.param.desc,
            aliado:props.param.aliado,
            //preview:props.param.image,
            category:props.param.category_id,
            categories:[],
            hall:props.param.hall_id,
            halls:[],
            dis:props.param.activo ? props.param.activo : 0,
            ally_id:props.param.ally_id,
            aliados:[],
            pri:props.param.pri,
            fee:props.param.ally_fee,
            if_offer:props.param.if_offer,
            price_offer:props.param.price_offer,
            stock:props.param.stock,
            sku:props.param.sku,
        }
        this.handleChange=this.handleChange.bind(this)
    }

    handleChange(e){
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }
    
    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        if(this.props.param.id){
            this.editar()
        }else{
            this.crear()
        }
        this.refs.btn.setAttribute("disabled", "disabled");
    }

    async crear(){

        const json={
            product:{
                name:this.state.name,
                desc:this.state.desc,
                price:this.state.price,
                status:1,
                activo: this.state.dis,
                ally_id:this.state.ally_id,
                tags:[],
                if_offer:this.state.if_offer,
                price_offer:this.state.price_offer,
                stock:parseInt(this.state.stock),
                sku:this.state.sku,
            }
        }

        if(this.state.preview){
            json.product.image=this.state.preview
        }else{
            json.product.image=empty
        }

        if(this.state.hall){
            json.product.hall_id=this.state.hall
        }

        try{
            await postData("products",json)
            this.props.cargar()
            this.props.close()
        }catch(err){
            console.log(err)
        }


    }

    async editar(){
        const json={
            product:{
                name:this.state.name,
                desc:this.state.desc,
                price:this.state.price,
                category_id:this.state.category,
                image:this.state.preview,
                activo: this.state.dis,
                ally_id:this.state.ally_id,
                pri:this.state.pri,
                ally_fee:this.state.fee,
                if_offer:this.state.if_offer,
                price_offer:this.state.price_offer,
                stock:parseInt(this.state.stock),
                sku:this.state.sku,
            }
        }

        if(this.state.hall){
            json.product.hall_id=this.state.hall
        }else{
            json.product.hall_id=null
        }

        try {
            await putData("products",this.props.param.id,json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }


    async componentDidMount(){


    }

    handleOfferCheck= (e) => {
        const { name,checked}= e.target
        this.setState({
            if_offer: checked
        })
    }



    render() {

        const { halls,aliados,categories,if_offer } = this.state;


        let preview

        if(this.state.preview){
            preview=<img src={this.state.preview} alt="preview" className="center"/>
        }else{
            preview=<img src={empty} alt="preview" className="center"/>
        }

        

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Producto</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>

                        {preview}
                        <div className="form-group">
                            <label htmlFor="image" className="col-form-label">Escoge una imagen :</label>
                            <input className="form-control" name="image" type="file" onChange={this.handleFile}  accept="image/jpeg" />
                        </div>
                        <div>
                            Carga tus fotos tambien en:
                            <a href ="https://drive.google.com/drive/folders/1tFXcbnCWj99WLQkeWlxBDcHCnRMx7KlG" target="_blank">
                                Google drive
                            </a>.
                        </div>
                        <div className="form-group">
                            <label htmlFor="sku" className="col-form-label" >Código :</label>
                            <input type="text" name="sku" className="form-control" onChange={this.handleChange} value={this.state.sku || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label" >Nombre :</label>
                            <input type="text" name="name" className="form-control" onChange={this.handleChange} value={this.state.name || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc" className="col-form-label" >Descripción :</label>
                            <textarea rows="4" name="desc" className="form-control" onChange={this.handleChange} value={this.state.desc || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pri" className="col-form-label" >Prioridad :</label>
                            <input type="number" min="1" max="70" name="pri" className="form-control" onChange={this.handleChange} value={this.state.pri || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="stock" className="col-form-label" >Stock :</label>
                            <input type="number" min="0" name="stock" className="form-control" onChange={this.handleChange} value={this.state.stock || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dis" className="col-form-label" >Estado :</label>
                            <select name="dis" onChange={this.handleChange} value={this.state.dis || ''} className="form-control" required >
                                <option  value={0}>Agotado</option>
                                <option  value={1}>Disponible</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="price" className="col-form-label" >Precio :</label>
                            <input type="number" min="1" name="price" className="form-control" onChange={this.handleChange} value={this.state.price || ''} required />
                        </div>


                        <div className="form-group">
                            <label className="form-check-label" htmlFor="if_offer">Activar oferta</label>
                            <input name="if_offer" onChange={this.handleOfferCheck} 
                            className="check_offer ml-2" type="checkbox" checked={if_offer? true : false}/>
                        </div>

                        {
                            if_offer?
                            <div className="form-row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="price_offer">Precio de oferta</label>
                                    <input type="number" min={1} value={this.state.price_offer} className="form-control" name="price_offer" placeholder="Monto" onChange={this.handleChange} required />
                                </div>
                            </div>
                            :''
                        }
                        <div className="modal-footer">
                            <button ref='btn' type="submit" className="btn btn-secondary">Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalProductoPocoStock