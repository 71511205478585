import axios from 'axios';

const url = 'https://api.fluio.com.co/';
//const url = 'http://96.126.120.138:45208/';

const config = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
};

export function allData(array) {
    return axios.all(array);
}

export function postData(endpoint, data) {
    return axios.post(url + endpoint, data, config);
}

export function getData(endpoint, headers) {
    return axios.get(url + endpoint, headers);
}

export function deleteData(endpoint, id) {
    return axios.delete(url + endpoint + '/' + id, config);
}
export function putData(endpoint, id, data) {
    return axios.put(url + endpoint + '/' + id, data, config);
}

export function getUser() {
    if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'));
    } else {
        return null;
    }
}

export function getHeaders() {
    if (localStorage.getItem('headers')) {
        let storeHeader = JSON.parse(localStorage.getItem('headers'));

        /** 

        let reqHeader={
            "token-type":storeHeader["token-type"],
            "access-token":storeHeader["access-token"],
            "client":storeHeader["client"],
            "uid":storeHeader["uid"]
        }

        */

        let reqHeader = {
            'token-type': 'Bearer',
            'access-token': 'zrv-4_3UdCLSvT3q53c8LQ',
            client: 'kN6lqBtB3jv4oaTCyYvohw',
            uid: 'donaiffviii@gmail.com',
        };

        return reqHeader;
    } else {
        return null;
    }
}

export function setHeaders(headers) {
    localStorage.setItem('headers', JSON.stringify(headers));
}

export function refreshHeaders(headers) {
    let newHeader = getHeaders();
    newHeader['access-token'] = headers['access-token'];
    newHeader['expiry'] = headers['expiry'];
    localStorage.setItem('headers', JSON.stringify(newHeader));
    console.log('nuevo header', getHeaders());
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function getArrayByTipo(array, tipo) {
    const datos = array.filter((value, index) => {
        return value.tipo == tipo;
    });

    return datos;
}

export const getTotalNormal = (cartItems, tipo, match, ordenType = 0) => {
    var total = 0;

    for (var i = 0; i < cartItems.length; i++) {
        total += parseInt(cartItems[i].total, 10);
    }

    if (isOrdenLocal(ordenType)) {
        switch (tipo) {
            case 0:
                total += parseFloat(cartItems[0].delivery);
                break;
            case 1:
                if (!match) {
                    total += parseFloat(cartItems[0].delivery);
                }
                break;
            case 2:
                total += parseFloat(cartItems[0].delivery);
                break;
            case 4:
                if (!match) {
                    total += parseFloat(cartItems[0].delivery);
                }
                break;
            case 5:
                total += parseFloat(cartItems[0].delivery);
                break;
            case 6:
                total += parseFloat(cartItems[0].delivery);
                break;
            default:
                break;
        }
    }

    return total;
};

export const getTotalPlan = (cartItems) => {
    var total = 0;

    for (var i = 0; i < cartItems.length; i++) {
        total += parseInt(cartItems[i].total, 10);
    }

    return total;
};

export const isOrdenLocal = (val) => {
    if (val === parseInt(0)) {
        return true;
    } else {
        return false;
    }
};
