import React, { Component } from 'react';
import { getTotalNormal } from '../../../servicios';
import { getDiaMesEspanol } from '../../../funciones/momentEspanol';
import { getTimeRange } from '../../../funciones/general';

class Core extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { productos, match } = this.props;

        if (productos.length > 0) {
            return (
                <div>
                    <h5 className="f-w-600 center tac">Mercado</h5>

                    <div className="row">
                        <div className="col-3">
                            <strong>Codigo</strong>
                        </div>
                        <div className="col-3">
                            <strong>Producto</strong>
                        </div>
                        <div className="col-3">
                            <strong>Cantidad</strong>
                        </div>

                        {/* <div className="col-3">
                            <strong>Aliado</strong>                       
                        </div> */}
                        <div className="col-3">
                            <strong>Costo</strong>
                        </div>
                    </div>
                    <br />

                    {productos.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="row">
                                    <div className="col-3">{item.sku ? item.sku : 'N/A'}</div>
                                    <div className="col-3">{item.producto}</div>
                                    <div className="col-3">{item.amount}</div>

                                    {/* <div className="col-3">
                                            {item.a_name} 
                                        </div> */}
                                    <div className="col-3">${item.total}</div>

                                    {item.com ? (
                                        <div className="col-12">
                                            <strong>Comentario:</strong>
                                            {item.com}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <hr />
                            </div>
                        );
                    })}

                    <div className="row">
                        <div className="col-4">
                            <strong>Entrega:</strong>
                        </div>
                        <div className="col-4">
                            {productos[0].hora == '1'
                                ? 'Menos de 90 minutos'
                                : getDiaMesEspanol(productos[0].fecha_i) + ' de ' + getTimeRange(productos[0].hora)}
                        </div>
                    </div>

                    {/*
                        !match?
                        <div className="row">
                            <div className="col-4">
                                <strong>Delivery:</strong>
                            </div>
                            <div className="col-4">
                                ${productos[0].delivery}             
                            </div>
                        </div>
                        :''
                    */}

                    {/*

                    <div className="row">
                        <div className="col-4">
                            <strong>Total:</strong>
                        </div>
                        <div className="col-4">
                            ${getTotalNormal(productos,1,match)}             
                        </div>
                    </div>

                    */}

                    <br />
                </div>
            );
        } else {
            return '';
        }
    }
}

export default Core;
